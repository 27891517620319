<template>
  <div class="popup-add-excursion">
    <div class="popup-add-excursion__wrapper">
      <Column class="grid-md-6 grid-lg-6">
        <p class="popup-add-excursion__title">{{ popupTitle }}</p>
        <p class="popup-add-excursion__description">Благодарим за Ваш интерес к проекту. Давайте
          открывать Ставрополье вместе!</p>
        <InputComponent id="excursionTitle"
          label="Название экскурсии"
          type="text"
          v-model="excursionTitle"/>
        <SelectComponent id="excursionType"
          label="Тип экскурсии"
          itemKey="name"
          :index="idSubtypes"
          v-on:change-index="changeIndex"
          :array="subtypes"/>
        <InputComponent id="excursionContacts"
          label="Контакты"
          type="text"
          v-model="excursionContacts"/>
        <Info>
          <p class="popup-add-object__info-text">Вы можете указать любую полезную контактную
            информацию (телефон, сайт, график работы, запись на экскурсии).</p>
        </Info>
        <InputComponent id="excursionCost"
          label="Стоимость, ₽"
          type="text"
          v-model="excursionCost"/>
        <InputComponent id="excursionDuration"
          label="Длительность"
          type="text"
          v-model="excursionDuration"/>
        <InputComponent id="excursionDistance"
          label="Дистанция"
          type="text"
          v-model="excursionDistance"/>
        <SelectComponent id="levelId"
          label="Уровень"
          itemKey="name"
          :index="excursionLevel"
          v-on:change-index="selectLevel"
          :array="levels"/>
        <InputComponent id="excursionDescription"
          v-model="excursionDescription"
          label="Описание"
          type="text"
          maxChars="700"
          multiline/>
        <Info>
          <p class="popup-add-object__info-text">В нескольких предложениях опишите ваш маршрут,
            чем он хорош и кому будет интересен. Перечислите объекты на маршруте и как до них
            можно добраться, если путь до них сложен и неочевиден."</p>
        </Info>
        <SelectComponent id="cityId"
          label="Город"
          itemKey="name"
          :index="excursionCityIdName"
          v-on:change-index="selectCity"
          :array="$cities.data"/>
        <InputComponent id="excursionVideo"
          label="Ссылка на видео"
          type="text"
          v-model="excursionVideo"/>
        <AddFile :params="{label: 'Фотографии'}"
          v-on:change-file="changeFile"
          v-on:change-preview="changePreview"/>
        <label class="popup-add-fact__label"
          for="objectTypeId">План тура</label>
        <div id="objectTypeId"
          class="agencies-guides__list-filter-select select"
          :class="showPlace ? 'select-opened' : ''"
          v-on:click="toggleSelectPlace">
          <Icon class="select-icon"
            xlink="arrow-navigation"
            :rotate="showPlace ? 0 : 180"
            viewport="0 0 20 20"/>
          <div class="selected">Выберите место</div>
          <div v-if="showPlace"
            class="select-list">
            <div v-for="place in $places.data"
              :key="place.id"
              v-on:click="selectPlace(place.id)"
              class="select-item">{{place.title}}
            </div>
          </div>
        </div>
        <Row v-if="excursionSelectedPlaces.length > 0"
          class="popup-add-excursion__previews-places"
          wrap>
          <div :key="preview.id"
            class="preview-place"
            v-for="(preview, index) in excursionSelectedPlaces">
            <img :src="preview.images[0]"
              class="preview-place-image"/>
            <Icon class="preview-place-remove"
              v-on:click.native="remove(preview.id)"
              :fill="$theme['--red']"
              viewport="0 0 20 20"
              xlink="close-filled"/>
            <div class="preview-place-number">{{index + 1}}</div>
            <div class="preview-place-title-holder">
              <span class="preview-place-title">{{title(preview.title)}}</span>
            </div>
          </div>
        </Row>
        <div>
          <Button v-on:click.native="addExcursion"
            :disabled="disabled"
            color="main">Добавить экскурсию
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupAddExcursion',
  data() {
    return {
      showPlace: false,
      objectTypeId: null,
      excursionTitle: '',
      excursionContacts: '',
      excursionCost: '',
      excursionDuration: '',
      excursionDistance: '',
      excursionDescription: '',
      excursionVideo: '',
      excursionSelectedPlaces: [],
      excursionLevel: 0,
      excursionCityId: 2,
      excursionImages: [],
      idSubtypes: 1,
      levels: [{
        id: 0,
        name: 'Легкий',
      }, {
        id: 1,
        name: 'Средний',
      }, {
        id: 2,
        name: 'Сложный',
      }],
      popupTitle: 'Создание экскурсии',
    };
  },
  computed: {
    disabled() {
      return !(this.excursionTitle.length > 0
          && this.excursionContacts.length > 0
          && this.excursionCost.length > 0
          && this.excursionDuration.length > 0
          && this.excursionDistance.length > 0
          && this.excursionDescription.length > 0
          && this.excursionVideo.length > 0
          && this.excursionSelectedPlaces.length > 0);
    },
    excursionCityIdName() {
      return this?.$cities?.data?.find((item) => item.id === this.excursionCityId)?.name;
    },
    subtypes() {
      return [{
        id: null,
        name: 'Выберите...',
      }, ...this.$tripSubTypes];
    },
  },
  created() {
    // eslint-disable-next-line max-len
    this.excursionCityId = this?.$cities?.data?.find((item) => item.slug === process.env.VUE_APP_DEFAULTH_CITY_SLUG)?.id;
    this.$store.dispatch('GET_ALL_PLACES_FROM_SERVER');
    this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');
    const additions = this?.$popup?.additions;
    if (additions?.id) {
      if (additions?.title) this.excursionTitle = additions.title;
      // eslint-disable-next-line camelcase
      if (additions?.subtype_id) this.idSubtypes = additions.subtype_id;
      if (additions?.description) this.excursionDescription = additions.description;
      if (additions?.contacts) this.excursionContacts = additions.contacts;
      if (additions?.cost) this.excursionCost = additions.cost;
      if (additions?.duration) this.excursionDuration = additions.duration;
      if (additions?.distance) this.excursionDistance = additions.distance;
      if (additions?.video) this.excursionVideo = additions.video;
      if (additions?.places) this.excursionSelectedPlaces = additions.places;
      if (additions?.level) this.excursionLevel = additions.level;
      // eslint-disable-next-line camelcase
      if (additions?.city_id) this.excursionCityId = additions.city_id;
      if (additions?.images) this.excursionImages = additions.images;
      this.popupTitle = 'Редактирование экскурсии';
    }
  },
  methods: {
    changeFile(file) {
      return file;
    },
    changePreview(preview) {
      this.excursionImages = preview;
    },
    changeIndex(id) {
      this.idSubtypes = id;
    },
    addExcursion() {
      let excursion = '?';
      if (this.excursionLevel) excursion += `&level=${this.excursionLevel}`;
      if (this.excursionDistance) excursion += `&distance=${this.excursionDistance}`;
      if (this.excursionDuration) excursion += `&duration=${this.excursionDuration}`;
      if (this.excursionTitle) excursion += `&title=${this.excursionTitle}`;
      if (this.excursionDescription) excursion += `&description=${this.excursionDescription}`;
      excursion += '&type=excursion';
      if (this.excursionCityId) excursion += `&city_id=${this.excursionCityId}`;
      if (this.excursionCost) excursion += `&cost=${this.excursionCost}`;
      if (this.excursionContacts) excursion += `&contacts=${this.excursionContacts}`;
      if (this.excursionVideo) excursion += `&videos=${this.excursionVideo}`;
      if (this.idSubtypes) excursion += `&subtype_id=${this.idSubtypes}`;
      if (this.excursionImages.length > 0) excursion += `&images=${this.previewsToString()}`;
      const formData = new FormData();
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      this.excursionSelectedPlaces.forEach((item) => {
        formData.append('places[]', item.id);
      });
      if (this?.$popup?.additions?.id) {
        this.$putAddExcursion(excursion, formData, config, this?.$popup?.additions?.id);
      } else {
        this.$postAddExcursion(excursion, formData, config);
      }
    },
    title(title) {
      return title.length > 20 ? `${title.slice(0, 20)}...` : title;
    },
    toggleSelectPlace() {
      this.showPlace = !this.showPlace;
    },
    selectCity(id) {
      this.excursionCityId = id;
    },
    selectLevel(id) {
      this.excursionLevel = id;
    },
    selectPlace(id) {
      this.objectTypeId = id;
      this.excursionSelectedPlaces = [...this.excursionSelectedPlaces,
        this.$places.data.find((item) => item.id === id)];
    },
    remove(id) {
      this.excursionSelectedPlaces = this.excursionSelectedPlaces.filter((item) => item.id !== id);
    },
    previewsToString() {
      let string = '';
      this.excursionImages.forEach((item, i, array) => {
        string += `${i === 0 ? '[' : ''}"${item}"${i !== array.length - 1 ? ',' : ']'}`;
      });
      return string;
    },
  },
};
</script>
